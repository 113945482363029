import React from 'react'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { makeStyles } from '@mui/styles'
import Divider from '@mui/material/Divider'
import { GetApp } from '@mui/icons-material'
import { praxis } from '../../../config/themeConfig'
import { DOWNLOAD_JPEG_VERSION, DOWNLOAD_ORIGINAL_VERSION } from '../../../constants/search'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  menuItem: {
    width: '295px',
    height: '25px',
    fontSize: '14px!important',
    overflow: 'hidden',
    flexShrink: '1',
    wordWrap: 'normal',
    whiteSpace: 'normal',
    color: praxis.themePrimary,
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  dropdownIcon: {
    color: '#BCBCCB',
  },
  company: {
    fontSize: '12px',
  },
  dropdownButton: {
    textTransform: 'none!important',
    fontSize: '15px',
    color: praxis.themePrimary,
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
  },
  dropdown: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 10px #00000027',
  },
  menuList: {
    padding: '0',
  },
  item: {
    width: '100%',
    textAlign: 'center',
    fontSize: '12px',
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
}))

export default function PopperMenu(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = (props, event) => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event, clickType = '') => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    if (clickType !== 'CANCEL') {
      props.trackSingleDownloadClick(clickType)
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.dropdownButton}
          data-cy='toggleButton'
        >
          <GetApp />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="top-end"
          disablePortal
          data-cy='popperElement'
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
              className={classes.dropdown}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={(event) => handleClose(event, 'CANCEL')}
                >
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    className={classes.menuList}
                  >
                    <a href={props.downloadLink} className={classes.link} data-cy={'downloadOriginalAnchorTag'}>
                      <MenuItem
                        className={classes.menuItem}
                        onClick={(event) => handleClose(event, DOWNLOAD_ORIGINAL_VERSION)}
                        data-cy='menu-item-original'
                      >
                        <div className={classes.item}>Download Original</div>
                      </MenuItem>
                    </a>
                    <Divider />
                    <a
                      href={props.downloadPreviewLink}
                      className={classes.link}
                      data-cy={'downloadPreviewAnchorTag'}
                    >
                      <MenuItem
                        className={classes.menuItem}
                        onClick={(event) => handleClose(event, DOWNLOAD_JPEG_VERSION)}
                        data-cy='menu-item-preview'
                      >
                        <div className={classes.item}>Download Preview</div>
                      </MenuItem>
                    </a>
                    <Divider />
                    <MenuItem
                      className={classes.menuItem}
                      onClick={(event) => handleClose(event, 'CANCEL')}
                      data-cy='menu-item-cancel'
                    >
                      <div className={classes.item}>Cancel</div>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  )
}
