import { useState } from 'react'
import cx from 'classnames'
import { isEmpty } from 'lodash'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { Button, Paper } from '@mui/material'
import { praxis } from '../../../config/themeConfig'

const styles = makeStyles((theme) => ({
  cardWrapperTopPadding: {
    paddingTop: useTheme().spacing(2),
  },
  cardWrapperBottomPadding: {
    paddingBottom: useTheme().spacing(2),
  },
  buttonPosition: {
    position: 'absolute',
    right: '20px',
  },
  buttonStyle: {
    color: praxis.primaryBlue,
    padding: '10px',
    marginLeft: '10px',
  },
  buttonSecondary: {
    backgroundColor: praxis.lightestGrey,
  },
  buttonPrimary: {
    backgroundColor: praxis.primaryBlue,
    color: praxis.contrastText,
  },
  cardHeader: {
    position: 'relative',
    paddingLeft: useTheme().spacing(2),
    paddingRight: useTheme().spacing(2),
    [useTheme().breakpoints.up('sm')]: {
      paddingLeft: useTheme().spacing(3),
      paddingRight: useTheme().spacing(3),
    },
  },
  cardTitle: {
    fontSize: '1.5em',
    display: 'inline',
  },
  cardContent: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  cardContentNoPadding: {
    padding: 0,
  },
}))

const CollapsibleCard = ({
  containerClasses = {},
  cardTitle = 'DETAILS',
  hideButtonText = 'HIDE',
  showButtonText = 'SHOW',
  fullWidthContent = false,
  isActionMode = false,
  disablePrimaryButton = false,
  primaryButtonText = 'PRIMARY',
  secondaryButtonText = 'SECONDARY',
  primaryButtonHandler = () => {},
  secondaryButtonHandler = () => {},
  collapseCallback = () => {},
  children,
}) => {
  const classes = styles()
  const [isCollapsed, setIsCollapsed] = useState(false)

  const collapseHandler = () => {
    setIsCollapsed(!isCollapsed)
    collapseCallback(cardTitle, !isCollapsed)
  }

  const buttonText = isCollapsed ? showButtonText : hideButtonText
  const showBottomPadding =
    !fullWidthContent || (fullWidthContent && isCollapsed)
  return (
    <Paper
      className={cx(classes.cardWrapperTopPadding, {
        [classes.cardWrapperBottomPadding]: showBottomPadding,
      })}
      elevation={1}
    >
      <div className={classes.cardHeader}>
        <span
          className={
            isEmpty(containerClasses)
              ? classes.cardTitle
              : containerClasses.cardTitle
          }
        >
          {cardTitle}
        </span>
        {isActionMode && (
          <span className={classes.buttonPosition}>
            <Button
              className={cx(classes.buttonStyle, classes.buttonSecondary)}
              onClick={secondaryButtonHandler}
              data-cy={'secondaryButton'}
            >
              {secondaryButtonText}
            </Button>
            <Button
              className={cx(classes.buttonStyle, classes.buttonPrimary)}
              variant="contained"
              onClick={primaryButtonHandler}
              disabled={disablePrimaryButton}
              data-cy={'primaryButton'}
            >
              {primaryButtonText}
            </Button>
          </span>
        )}
        {!isActionMode && (
          <Button
            className={cx(classes.buttonPosition, classes.buttonStyle)}
            onClick={collapseHandler}
            data-testid={'toggleButton'}
          >
            {buttonText}
          </Button>
        )}
      </div>
      {!isCollapsed && (
        <div
          className={
            fullWidthContent
              ? classes.cardContentNoPadding
              : classes.cardContent
          }
        >
          {children}
        </div>
      )}
    </Paper>
  )
}
export default CollapsibleCard
