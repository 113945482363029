import * as React from 'react'
import { makeStyles } from '@mui/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Button, Popover } from '@mui/material'

import {
  FILTER_BRAND_KEY,
  FILTER_CHANNEL_KEY,
  FILTER_FILE_TYPE_KEY,
  FILTER_UPLOAD_DATE_KEY,
  FILTER_CLASS_KEY,
  FILTER_DEPARTMENT_KEY,
  FACET_IMAGEDATA_KEY,
  FACET_COLOR_KEY,
  FILTER_CLASSIFICATION_KEY,
  FILTER_IS_ARCHIVED_KEY,
  FILTER_AI_CLASSIFICATION_TALENT,
} from '../../../constants/search'
import CustomFilterOptions from '../../../components/CustomFilterOptions'

import { useSearchContext } from '../../Search/SearchContext'

import FileTypeFilter from './FileTypeFilter'
import UploadDateFilter from './UploadDateFilter'
import AssetSubjectFilter from './AssetSubjectFilter'
import ColorFilter from './ColorFilter'
import ClassificationFilter from './ClassificationFilter'
import ArchivalAssetFilter from './ArchivalAssetFilter'
import AIClassificationFilter from './AIClassificationFilter'

const useStyles = makeStyles({
  filterButton: {
    padding: '5px',
    backgroundColor: '#fff',
  },
  filtersLabel: {
    fontFamily: 'Helvetica',
    fontWeight: '400',
    textTransform: 'none',
  },
  displayIcon: {
    marginLeft: '0px',
  },
  filtersCountDiv: {
    background: '#666666',
    color: '#fff',
    borderRadius: '10px',
    padding: '0px 4px',
    marginLeft: '2px',
  },
  actionGroup: {
    width: 'inherit',
    padding: '10px',
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
  },
})

export default function CustomFilterBox({ filterConfig = {} }) {
  const {
    label = '',
    id = '',
    key = '',
    filtersCount = Number(0),
  } = filterConfig
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { facets = {}, filters = {}, channelFacets = {} } = useSearchContext()
  const { facetResponse = [] } = facets

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getAvailableFacets = (key = '') => {
    let facetResponseData = {}
    facetResponseData =
      (facetResponse || []).find((facetData = {}) => facetData.path === key) ||
      {}
    return facetResponseData
  }

  let availableFacets = getAvailableFacets(key)
  if (key === FILTER_CHANNEL_KEY) {
    availableFacets = channelFacets
  }
  const selectedFilterValues = filters[key]

  return (
    <>
      <Button
        type="secondary"
        variant="outlined"
        data-cy={`customFilterButton-${key}`}
        className={classes.filterButton}
        endIcon={<KeyboardArrowDownIcon />}
        classes={{
          endIcon: classes.displayIcon,
        }}
        onClick={handleClick}
      >
        <span className={classes.filtersLabel}>{label}</span>{' '}
        {filtersCount ? (
          <span className={classes.filtersCountDiv}>{filtersCount}</span>
        ) : (
          ''
        )}
      </Button>
      <Popover
        disableScrollLock={true}
        open={Boolean(anchorEl)}
        id={id}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {(() => {
          switch (key) {
            case FILTER_FILE_TYPE_KEY:
              return (
                <FileTypeFilter
                  parentClasses={classes}
                  closePopOver={handleClose}
                  availableFacets={availableFacets}
                  selectedFilterValues={selectedFilterValues}
                />
              )
            case FILTER_CLASSIFICATION_KEY:
              return (
                <ClassificationFilter
                  parentClasses={classes}
                  closePopOver={handleClose}
                  availableFacets={availableFacets}
                  selectedFilterValues={selectedFilterValues}
                />
              )
            case FILTER_UPLOAD_DATE_KEY:
              return (
                <UploadDateFilter
                  classes={classes}
                  closePopOver={handleClose}
                  selectedFilterValues={selectedFilterValues}
                />
              )
            case FACET_IMAGEDATA_KEY:
              return (
                <AssetSubjectFilter
                  closePopOver={handleClose}
                  availableFilters={filters}
                />
              )
            case FACET_COLOR_KEY:
              return (
                <ColorFilter
                  closePopOver={handleClose}
                  availableFilters={filters}
                />
              )
            case FILTER_IS_ARCHIVED_KEY:
              return (
                <ArchivalAssetFilter
                  closePopOver={handleClose}
                  availableFilters={filters}
                  filterKey={key}
                />
              )
            case FILTER_AI_CLASSIFICATION_TALENT:
              return (
                <AIClassificationFilter
                  closePopOver={handleClose}
                  availableFilters={filters}
                />
              )
            case FILTER_BRAND_KEY:
            case FILTER_CHANNEL_KEY:
            case FILTER_DEPARTMENT_KEY:
            case FILTER_CLASS_KEY:
              return (
                <CustomFilterOptions
                  parentClasses={classes}
                  filterType={id}
                  filterKey={key}
                  closePopOver={handleClose}
                  availableFacets={availableFacets}
                  selectedFilters={filters}
                />
              )
            default:
              return null
          }
        })()}
      </Popover>
    </>
  )
}
