import { Badge, Chip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { useDispatch, useSelector } from 'react-redux'

import {
  removeFilterValue,
  removeSearchTerm,
  clearAllAppliedFilters,
  defaultFilters,
} from '../../../store/search/searchSlice'
import {
  COLOR_IDENTIFIER_GROUP,
  SEARCH_TEXT,
  filterKeyLabels,
} from '../../../constants/search'
import { createColorChipContent } from '../../../utils/search'
import { convertFiltersAndTextSearchToConfig } from '../../../mappers/searchMapper'
import { BetaAnalytics } from '../../../../analytics/betaSearch'
import { useSearchContext } from '../SearchContext'

const useStyles = makeStyles({
  appliedFiltersDiv: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  deleteIcon: {
    color: '#CC0000 !important',
  },
  clearAllText: {
    color: '#CC0000',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  filterBadge: {
    fontSize: '10px !important',
    backgroundColor: '#188295 !important',
  },
})

function AppliedFiltersLayout() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { trackAnalyticsData = () => {} } = useSearchContext()
  const {
    filters = {},
    searchTerm = '',
    facets = {},
  } = useSelector((state) => state?.newSearch || {})
  const filtersConfig = convertFiltersAndTextSearchToConfig(filters, facets)
  const handleDelete = (filter) => {
    if (filter.group === SEARCH_TEXT) {
      dispatch(removeSearchTerm(filter))
    } else {
      dispatch(removeFilterValue(filter))
      trackAnalyticsData({
        eventType: BetaAnalytics.event.REMOVE_FILTER,
        eventName: filterKeyLabels?.[filter.group] || filter.group,
        eventData: { key: filter.group, value: filter.value },
      })
    }
  }

  const handleClearAllTrackingEvent = () => {
    const customFiltersApplied = {}
    for (const key in filters) {
      if (!(key in defaultFilters)) {
        if (!customFiltersApplied[key]) {
          customFiltersApplied[key] = filters[key]
        }
      }
    }
    trackAnalyticsData({
      eventType: BetaAnalytics.event.REMOVE_ALL_FILTERS,
      eventName: BetaAnalytics.event.REMOVE_ALL_FILTERS,
      eventData: {
        key: 'existing_custom_filters',
        value: JSON.stringify(customFiltersApplied),
      },
    })
  }

  const handleClearAllFilters = () => {
    dispatch(clearAllAppliedFilters())
    handleClearAllTrackingEvent()
  }
  return (
    <div className={classes.appliedFiltersDiv}>
      {(filtersConfig || []).map((filter, index) => {
        const { value = '', group = '', label = '' } = filter
        const chipLabel = COLOR_IDENTIFIER_GROUP.includes(group)
          ? createColorChipContent(value)
          : label
        const filterBadge = filterKeyLabels[group] || 0
        return (
          value && (
            <Badge
              badgeContent={filterBadge}
              color="secondary"
              classes={{ badge: classes.filterBadge }}
            >
              <Chip
                key={index}
                cl
                onDelete={() => handleDelete(filter)}
                label={chipLabel}
                deleteIcon={<ClearOutlinedIcon data-cy={`delete_${filterBadge}_${chipLabel}`}/>}
                classes={{
                  deleteIcon: classes.deleteIcon,
                }}
                data-cy={`${filterBadge}_${chipLabel}`}
              />
            </Badge>
          )
        )
      })}
      {filtersConfig.length > 1 && (
        <Typography
          className={classes.clearAllText}
          onClick={() => handleClearAllFilters()}
          data-cy={`clearAllFilters`}
        >
          Clear All
        </Typography>
      )}
    </div>
  )
}

export default AppliedFiltersLayout
