import React, { useState } from 'react'
import { omit } from 'lodash'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'

import { replaceFilters } from '../../../store/search/searchSlice'
import {
  FILTER_IMAGEDATA_ANIMALS_KEY,
  FILTER_IMAGEDATA_FOOD_KEY,
  FILTER_IMAGEDATA_PEOPLE_KEY,
} from '../../../constants/search'
import CustomButtonGroup from '../../../components/CustomButtonGroup'
import { BetaAnalytics } from '../../../../analytics/betaSearch'
import { useSearchContext } from '../SearchContext'

const useStyles = makeStyles({
  assetSubjectContainer: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  assetSubject: {
    padding: '10px',
  },
  subjectLabel: {
    background: '#D9D9D9',
    color: '#333333',
    paddingLeft: '10px',
  },
  actionGroup: {
    width: 'inherit',
    padding: '10px',
    display: 'flex',
    gap: '10px',
    justifyContent: 'flex-end',
  },
  radioButton: {
    color: '#188295 !important',
    '&.Mui-checked': {
      color: '#115E6C !important',
    },
  },
})

function AssetSubjectFilter({
  closePopOver = () => {},
  availableFilters = {},
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { trackAnalyticsData = () => {} } = useSearchContext()

  const [people, setPeople] = useState(
    availableFilters?.[FILTER_IMAGEDATA_PEOPLE_KEY]?.[0] || '',
  )
  const [food, setFood] = useState(
    availableFilters?.[FILTER_IMAGEDATA_FOOD_KEY]?.[0] || '',
  )
  const [animals, setAnimals] = useState(
    availableFilters?.[FILTER_IMAGEDATA_ANIMALS_KEY]?.[0] || '',
  )

  const onClickApply = () => {
    let assetSubjectFilters = { ...availableFilters }
    const analyticsFilterData = []
    if (people) {
      assetSubjectFilters[FILTER_IMAGEDATA_PEOPLE_KEY] = [people]
      analyticsFilterData[FILTER_IMAGEDATA_PEOPLE_KEY] = people
    } else if (FILTER_IMAGEDATA_PEOPLE_KEY in assetSubjectFilters) {
      assetSubjectFilters = omit(assetSubjectFilters, [
        FILTER_IMAGEDATA_PEOPLE_KEY,
      ])
    }
    if (food) {
      assetSubjectFilters[FILTER_IMAGEDATA_FOOD_KEY] = [food]
      analyticsFilterData[FILTER_IMAGEDATA_FOOD_KEY] = food
    } else if (FILTER_IMAGEDATA_FOOD_KEY in availableFilters) {
      assetSubjectFilters = omit(assetSubjectFilters, [
        FILTER_IMAGEDATA_FOOD_KEY,
      ])
    }
    if (animals) {
      assetSubjectFilters[FILTER_IMAGEDATA_ANIMALS_KEY] = [animals]
      analyticsFilterData[FILTER_IMAGEDATA_ANIMALS_KEY] = animals
    } else if (FILTER_IMAGEDATA_ANIMALS_KEY in availableFilters) {
      assetSubjectFilters = omit(assetSubjectFilters, [
        FILTER_IMAGEDATA_ANIMALS_KEY,
      ])
    }
    dispatch(replaceFilters({ filters: assetSubjectFilters }))
    closePopOver()
    trackAnalyticsData({
      eventType: BetaAnalytics.event.ADD_FILTER,
      eventName: BetaAnalytics.event.FILTER_ASSET_DESCRIPTION,
      eventData: analyticsFilterData,
    })
  }
  return (
    <Grid container className={classes.assetSubjectContainer}>
      <Grid item className={classes.assetSubject}>
        <FormControl>
          <FormLabel
            id="people-row-radio-buttons-group-label"
            className={classes.subjectLabel}
          >
            People
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="people-radio-buttons-group-label"
            name="people-radio-buttons-group"
            value={people}
            onChange={(event) => setPeople(event.target.value)}
          >
            <FormControlLabel
              value="With People"
              control={<Radio className={classes.radioButton} />}
              label="With"
              data-cy={`with_people`}
            />
            <FormControlLabel
              value="Without People"
              control={<Radio className={classes.radioButton} />}
              label="Without"
              data-cy={`without_people`}
            />
            <FormControlLabel
              value=""
              control={<Radio className={classes.radioButton} />}
              label="No Preference"
              data-cy={`no_preference_people`}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item className={classes.assetSubject}>
        <FormControl>
          <FormLabel
            id="food-row-radio-buttons-group-label"
            className={classes.subjectLabel}
          >
            Food
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="food-radio-buttons-group-label"
            name="food-radio-buttons-group"
            value={food}
            onChange={(event) => setFood(event.target.value)}
          >
            <FormControlLabel
              value="With Food"
              control={<Radio className={classes.radioButton} />}
              label="With"
              data-cy={`with_food`}
            />
            <FormControlLabel
              value="Without Food"
              control={<Radio className={classes.radioButton} />}
              label="Without"
              data-cy={`without_food`}
            />
            <FormControlLabel
              value=""
              control={<Radio className={classes.radioButton} />}
              label="No Preference"
              data-cy={`no_preference_food`}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item className={classes.assetSubject}>
        <FormControl>
          <FormLabel
            id="animal-row-radio-buttons-group-label"
            className={classes.subjectLabel}
          >
            Animal
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="animal-radio-buttons-group-label"
            name="animal-radio-buttons-group"
            value={animals}
            onChange={(event) => setAnimals(event.target.value)}
          >
            <FormControlLabel
              value="With Animal"
              control={<Radio className={classes.radioButton} />}
              label="With"
              data-cy={`with_animal`}
            />
            <FormControlLabel
              value="Without Animal"
              control={<Radio className={classes.radioButton} />}
              label="Without"
              data-cy={`without_animal`}
            />
            <FormControlLabel
              value=""
              control={<Radio className={classes.radioButton} />}
              label="No Preference"
              data-cy={`no_preference_animal`}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <CustomButtonGroup onClickApply={onClickApply} hideClearButton={true} />
      </Grid>
    </Grid>
  )
}

export default AssetSubjectFilter
