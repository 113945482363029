import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Grid, Typography } from '@mui/material'
import { PhotoCamera, ChevronLeft } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'

import ImageCard from './ImageCard'
import { useSearchContext } from '../SearchContext'
import FilterByImage from '../Filters/FilterByImage'

const useStyles = makeStyles({
  imageButton: {
    marginLeft: '20px',
    maxHeight: '40px',
    color: '#188295',
    '& $cameraIcon': {
      color: '#188295',
      marginRight: 5,
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#188295',
      '& $cameraIcon': {
        color: 'white',
      },
    },
  },
})

const SearchByImageLayout = () => {
  const classes = useStyles()
  const {
    previewImageRS = null,
    setImageSearchDialogOpen = () => {},
    triggerBackToSearch = () => {},
  } = useSearchContext()
  const { isAuthorized = () => {} } = useAuth()
  const { isAdGroupSuperAdmin: isAdmin = false } = useSelector(
    (state) => state?.auth || {},
  )
  const {
    ENABLE_REVERSE_IMAGE_SEARCH,
    REACT_APP_ROLES_CONFIG: { search = [] } = {},
  } = useEnv()
  const searchByImage =
    isAuthorized(search) && (ENABLE_REVERSE_IMAGE_SEARCH || isAdmin)

  return (
    <Grid
      container
      direction={'column'}
      spacing={2}
      style={{ padding: '20px 20px 0px' }}
    >
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h5" gutterBottom>
            {'Search By Image'}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            id="searchByImage_BackToSearch"
            data-cy="searchByImage_BackToSearch"
            onClick={() => {
              triggerBackToSearch()
            }}
            className={classes.imageButton}
            startIcon={
              <ChevronLeft
                className={classes.refreshIcon}
                data-cy="startOverButton"
              />
            }
          >
            Back To Search
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container alignItems={'center'} spacing={2}>
          <Grid item>
            <ImageCard previewImage={previewImageRS} />
          </Grid>
          <Grid item>
            {searchByImage && (
              <Button
                variant="contained"
                color="secondary"
                data-cy='searchByImageButton'
                onClick={() => {
                  setImageSearchDialogOpen(true)
                }}
                startIcon={<PhotoCamera className={classes.cameraIcon} />}
              >
                Search by image
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <FilterByImage />
    </Grid>
  )
}

export default SearchByImageLayout
