import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { isEmpty, get } from 'lodash'
import { withStyles } from '@mui/styles'
import { IconButton, Button, Grid } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { Formik, Field } from 'formik'
import ModalDialog from '../../ModalDialog/ModalDialog'
import {
  ConfidentialFormField,
  DateFormField,
  InfluencerFormField,
  PeopleFormField,
  SelectFormField,
  TextFormField,
  BrandsFormField,
} from '../../FormikFormFields'
import apiConfig from '../../../config/apiConfig'
import {
  deleteRoundelFormKeys,
  validateIsRequired,
} from '../../../helpers/ProjectFormHelpers'
import { sortMyArray } from '../../../helpers/UtilityHelper'
import {
  EDIT_PROJECT_KEYS_TO_REMOVE,
  TASK_FIELD_SEQUENCE_START,
} from '../../../constants/projectTask'

// CONSTANTS
const KEY = apiConfig.key
const BUILD_FIELDS_URL = apiConfig.projects.buildProjectFieldsUrl
const UPDATE_PROJECT_URL = apiConfig.projects.createProjectUrl

const styles = {
  confidentialFields: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
  },
  releaseDate: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'sticky',
    bottom: '-24px',
    width: '100%',
    backgroundColor: 'white',
    padding: '10px 0 10px 10px',
    marginTop: '10px',
  },
  errorMessage: {
    color: 'red',
    fontSize: '12px',
    marginLeft: '35px',
  },
  createForm: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  createFormFields: {
    width: '47%',
    padding: '0px 20px',
  },
}

export class EditProjectForm extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      projectTypeDictionary: {},
      formData: {},
      initialValues: {},
      error: {
        emailAddress: '',
      },
    }
  }

  componentDidMount() {
    const { projectDetails = {} } = this.props
    const {
      project_uuid = '',
      project_id = '',
      project_type = '',
      run_date = null,
    } = projectDetails
    let projectId = project_id

    if (run_date && project_type === 'Weekly Ad') {
      projectId = run_date
    }

    const requestData = {
      project_id: projectId,
      project_type,
      project_uuid,
    }

    axios
      .post(`${BUILD_FIELDS_URL}?key=${KEY}`, requestData)
      .then((result = {}) => {
        const { data = [] } = result
        const projectData = data[0]

        const initialValues = Object.keys(projectData).reduce(
          (initVals, key) => {
            switch (key) {
              case 'metadata_list':
                projectData[key].forEach((metadata = {}) => {
                  const { metadata_name = '', metadata_value = '' } = metadata
                  initVals[metadata_name] = metadata_value
                })
                return initVals
              case 'project_initiator': {
                const firstName = get(
                  projectData,
                  `project_initiator.first_name`,
                  '',
                )
                const lastName = get(
                  projectData,
                  `project_initiator.last_name`,
                  '',
                )
                initVals[key] = `${firstName} ${lastName}`
                return initVals
              }
              default:
                initVals[key] = projectData[key]
                return initVals
            }
          },
          {},
        )
        this.setState({ formData: projectData, initialValues })
      })
      .catch((e = {}) => {
        throw new Error(e.message)
      })
  }

  onSubmit = (values = {}) => {
    const { formData } = this.state
    const channelISMP = get(values, 'channel_ISMP', '')
    const channel = get(values, 'channel', '')
    const project_type = formData.project_type
    const run_date = get(values, 'run_date', '')
    let projectId = get(values, 'project_id')

    if (run_date && project_type === 'Weekly Ad') {
      projectId = run_date
    }
    const requestData = {
      project_id: projectId,
      project_type: formData.project_type,
      project_uuid: formData.project_uuid,
    }

    const postSubmitData = {
      ...values,
      project_initiator: get(formData, 'project_initiator.login_id', ''),
      run_date: values.run_date || new Date(),
      channel: channel ? channel : channelISMP,
      due_date: get(values, 'due_date', new Date()),
      project_admins: get(values, 'project_admins', []).filter(
        (admin) => !get(admin, 'isBadEmail', false),
      ),
    }
    let finalPostSubmitData = Object.assign({}, postSubmitData)
    if (project_type === 'Experiential Marketing') {
      finalPostSubmitData = Object.assign({}, postSubmitData, {
        channel: 'Experiential',
      }) //default value
    } else if (project_type === 'Roundel') {
      finalPostSubmitData = Object.assign(
        {},
        deleteRoundelFormKeys(postSubmitData, EDIT_PROJECT_KEYS_TO_REMOVE),
      )
    }

    axios
      .post(`${BUILD_FIELDS_URL}?key=${KEY}`, requestData)
      .then((result = {}) => {
        const { data = [] } = result
        const projectData = data[0]
        const projectUuid = get(projectData, `project_uuid`, '')
        if (projectUuid === '' || projectUuid === formData.project_uuid) {
          axios
            .put(`${UPDATE_PROJECT_URL}?key=${KEY}`, finalPostSubmitData)
            .then(() => {
              this.props.onClose()
            })
            .catch((e = {}) => {
              const { message = '' } = e
              throw new Error(message)
            })
        } else {
          const value = {
            emailAddress: get(formData, 'project_initiator.emailAddress', ''),
          }

          this.setState({ errors: value })
        }
      })
      .catch((e = {}) => {
        const { message = '' } = e
        throw new Error(message)
      })
  }

  clearErrors = () => {
    const { errors } = this.state
    if (!isEmpty(errors)) {
      this.setState({ errors: {} })
    }
  }

  render() {
    const {
      open = false,
      onClose = () => {},
      classes = {},
      projectDetails = {},
    } = this.props
    const { formData, initialValues, errors } = this.state
    const { metadata_list = [], project_type: projectType } = formData
    const { project_type = '' } = projectDetails
    const unsortedMetadataList = metadata_list.filter((field = {}) => {
      const fieldName = get(field, 'metadata_name', '')
      if (fieldName !== 'project_code' && fieldName !== 'release_date') {
        return field
      }
    })

    const sortedMetaDataList = sortMyArray(
      unsortedMetadataList,
      'field_sequence',
      'asc',
    )
    return (
      <ModalDialog
        open={open}
        onClose={onClose}
        title="Edit Project Details"
        aria-labelledby="form-dialog-title"
      >
        {!isEmpty(initialValues) && (
          <Formik
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            validateOnChange={false}
          >
            {(formikProps) => {
              const { handleSubmit = () => {}, values = {} } = formikProps
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid container item className={classes.createForm}>
                      <Grid item className={classes.createFormFields}>
                        <Field
                          name="project_type"
                          label="Project Type"
                          disabled
                          component={TextFormField}
                          data-cy={`edit_project_type`}
                        />
                      </Grid>
                      <Grid item className={classes.createFormFields}>
                        <Field
                          name="confidential"
                          component={ConfidentialFormField}
                        />
                      </Grid>
                      {values.confidential && (
                        <React.Fragment>
                          <div className={classes.confidentialFields}>
                            <Grid item className={classes.createFormFields}>
                              <Field
                                iconLabel={'Label'}
                                name={'project_code'}
                                label={'Project Code Name'}
                                component={TextFormField}
                                data-cy={`edit_project_code`}
                              />
                            </Grid>
                            <span className={classes.releaseDate}>
                              <Field
                                iconLabel={'Date'}
                                name={'project_release_date'}
                                label={'Internal Release Date'}
                                component={DateFormField}
                                data-cy={`edit_project_release_date`}
                              />
                              <div>
                                <IconButton
                                  style={{ height: '32px', width: '32px' }}
                                  variant="contained"
                                  onClick={() =>
                                    formikProps.setFieldValue(
                                      'project_release_date',
                                      null,
                                    )
                                  }
                                  size="large"
                                  data-cy={`edit_project_release_date_button`}
                                >
                                  <Clear />
                                </IconButton>
                              </div>
                            </span>
                          </div>
                        </React.Fragment>
                      )}
                      {sortedMetaDataList
                        .filter(
                          (dataList) =>
                            dataList.field_sequence < TASK_FIELD_SEQUENCE_START,
                        )
                        .map((field = {}) => {
                          switch (field.metadata_type || '') {
                            case 'autocomplete': {
                              let menuItems = field.values.map((value) => ({
                                text: value,
                                value: value,
                              }))
                              if (projectType === 'Beauty Boxes') {
                                menuItems.push({
                                  text: 'Beauty Boxes',
                                  value: 'Beauty Boxes',
                                })
                              }
                              return (
                                <Grid item className={classes.createFormFields}>
                                  <Field
                                    key={field.metadata_name}
                                    required={field.is_required}
                                    validate={validateIsRequired(
                                      field.is_required,
                                      field.display_text,
                                    )}
                                    iconLabel={field.icon_descriptor}
                                    name={field.metadata_name}
                                    label={field.display_text}
                                    component={SelectFormField}
                                    menuItems={menuItems}
                                    data-cy={`edit_autocomplete_${field.metadata_name}`}
                                  />
                                </Grid>
                              )
                            }
                            case 'contact':
                              return (
                                <Grid item className={classes.createFormFields}>
                                  <Field
                                    required={field.is_required}
                                    validate={validateIsRequired(
                                      field.is_required,
                                      field.display_text,
                                    )}
                                    key={field.metadata_name}
                                    iconLabel={field.icon_descriptor}
                                    name={field.metadata_name}
                                    label={field.display_text}
                                    component={PeopleFormField}
                                    data-cy={`edit_people_${field.metadata_name}`}
                                  />
                                </Grid>
                              )
                            case 'date':
                              if (
                                project_type === 'Weekly Ad' &&
                                field.metadata_name === 'run_date'
                              ) {
                                return (
                                  <Grid
                                    item
                                    className={classes.createFormFields}
                                  >
                                    <Field
                                      disabled
                                      required={field.is_required}
                                      validate={validateIsRequired(
                                        field.is_required,
                                        field.display_text,
                                      )}
                                      key={field.metadata_name}
                                      iconLabel={field.icon_descriptor}
                                      name={field.metadata_name}
                                      label={field.display_text}
                                      component={DateFormField}
                                      data-cy={`edit_${field.metadata_name}`}
                                    />
                                  </Grid>
                                )
                              }

                              return (
                                <Grid item className={classes.createFormFields}>
                                  <Field
                                    required={field.is_required}
                                    validate={validateIsRequired(
                                      field.is_required,
                                      field.display_text,
                                    )}
                                    key={field.metadata_name}
                                    iconLabel={field.icon_descriptor}
                                    name={field.metadata_name}
                                    label={field.display_text}
                                    component={DateFormField}
                                    data-cy={`edit_date_${field.metadata_name}`}
                                  />
                                </Grid>
                              )
                            default:
                              if (field.metadata_name === 'project_id') {
                                return (
                                  <Grid
                                    item
                                    className={classes.createFormFields}
                                  >
                                    <Field
                                      required={field.is_required}
                                      validate={validateIsRequired(
                                        field.is_required,
                                        field.display_text,
                                      )}
                                      key={field.metadata_name}
                                      iconLabel={field.icon_descriptor}
                                      name={field.metadata_name}
                                      label={field.display_text}
                                      component={TextFormField}
                                      error={!isEmpty(errors)}
                                      onKeyUp={this.clearErrors}
                                      data-cy={`edit_${field.metadata_name}`}
                                    />
                                    {!isEmpty(errors) && (
                                      <p className={classes.errorMessage}>
                                        Project with this Project ID already
                                        exists. Please contact &nbsp;
                                        <strong>{errors.emailAddress}</strong>
                                        <br />
                                      </p>
                                    )}
                                  </Grid>
                                )
                              }
                              if (field.metadata_name === 'project_initiator') {
                                return (
                                  <Grid
                                    item
                                    className={classes.createFormFields}
                                  >
                                    <Field
                                      disabled
                                      required={field.is_required}
                                      validate={validateIsRequired(
                                        field.is_required,
                                        field.display_text,
                                      )}
                                      key={field.metadata_name}
                                      iconLabel={field.icon_descriptor}
                                      name={field.metadata_name}
                                      label={field.display_text}
                                      component={TextFormField}
                                      data-cy={`edit_${field.metadata_name}`}
                                    />
                                  </Grid>
                                )
                              }

                              if (field.metadata_name === 'brand') {
                                return (
                                  <Grid
                                    item
                                    className={classes.createFormFields}
                                  >
                                    <Field
                                      required={field.is_required}
                                      validate={validateIsRequired(
                                        field.is_required,
                                        field.display_text,
                                      )}
                                      key={field.metadata_name}
                                      iconLabel={field.icon_descriptor}
                                      name={field.metadata_name}
                                      label={field.display_text}
                                      component={BrandsFormField}
                                      data-cy={`edit_${field.metadata_name}`}
                                    />
                                  </Grid>
                                )
                              }

                              if (
                                projectType === 'Influencer Marketing' &&
                                field.metadata_name === 'project_name'
                              ) {
                                return (
                                  <Grid
                                    item
                                    className={classes.createFormFields}
                                  >
                                    <Field
                                      required={field.is_required}
                                      validate={validateIsRequired(
                                        field.is_required,
                                        field.display_text,
                                      )}
                                      key={field.metadata_name}
                                      iconLabel={field.icon_descriptor}
                                      name={field.metadata_name}
                                      label={field.display_text}
                                      component={InfluencerFormField}
                                      data-cy={`edit_${field.metadata_name}`}
                                    />
                                  </Grid>
                                )
                              }

                              return (
                                <Grid item className={classes.createFormFields}>
                                  <Field
                                    required={field.is_required}
                                    validate={validateIsRequired(
                                      field.is_required,
                                      field.display_text,
                                    )}
                                    key={field.metadata_name}
                                    iconLabel={field.icon_descriptor}
                                    name={field.metadata_name}
                                    label={field.display_text}
                                    component={TextFormField}
                                    data-cy={`edit_${field.metadata_name}`}
                                  />
                                </Grid>
                              )
                          }
                        })}
                    </Grid>
                    <div className={classes.footer}>
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="secondary"
                        data-cy={`edit_save_button`}
                      >
                        Save
                      </Button>
                    </div>
                  </Grid>
                </form>
              )
            }}
          </Formik>
        )}
      </ModalDialog>
    )
  }
}

EditProjectForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  projectDetails: PropTypes.shape({
    project_id: PropTypes.string,
    project_type: PropTypes.string,
    project_uuid: PropTypes.string,
  }),
  classes: PropTypes.object,
}

export default withStyles(styles)(EditProjectForm)
