import React from 'react'
import TextField from '@mui/material/TextField'
import IconWrapper from '../IconWrapper/IconWrapper'
import { FormikFieldPropTypes } from '../../constants/projects'

export const TextFormField = ({
  field = {},
  form = {},
  label = '',
  iconLabel = '',
  dense = false,
  isRequired = false,
  ...props
}) => (
  <IconWrapper iconTag={iconLabel}>
    <TextField
      data-cy={`textField_${field.name}`}
      variant="outlined"
      required={isRequired}
      label={label}
      placeholder={`Enter ${label}`}
      InputLabelProps={{ shrink: true }}
      error={!!form.errors[field.name]}
      helperText={form.errors[field.name]}
      fullWidth={!dense}
      style={{ marginLeft: '10px' }}
      {...field}
      {...props}
    />
  </IconWrapper>
)

TextFormField.propTypes = FormikFieldPropTypes
export default TextFormField
