import React, { useState, useEffect } from 'react'
import { DateRangePicker } from 'react-date-range'
import { Button, Grid } from '@mui/material'
import { format } from 'date-fns'
import { defaultInputRanges, defaultStaticRanges } from './defaultRanges'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import CustomButtonGroup from '../../components/CustomButtonGroup'

const DateRangeSelector = ({
  classes,
  ranges,
  onChange,
  onApplyFilter,
  selectedFilterValues = [],
  ...rest
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  useEffect(() => {
    if (
      selectedFilterValues.length &&
      selectedFilterValues.length === Number(2)
    ) {
      setSelectedDateRange({
        startDate: new Date(selectedFilterValues[0]),
        endDate: new Date(selectedFilterValues[1]),
        key: 'selection',
      })
    }
  }, [selectedFilterValues])

  function formatDateDisplay(date, defaultText) {
    if (!date) return defaultText
    return format(date, 'MM/dd/yyyy')
  }

  const handleSelect = (ranges) => {
    setSelectedDateRange(ranges.selection)
  }

  const onClickApply = () => {
    const newDateRange = [
      format(selectedDateRange.startDate, 'MM-dd-yyyy'),
      format(selectedDateRange.endDate, 'MM-dd-yyyy'),
    ]
    onApplyFilter(newDateRange)
  }

  const onClickClear = () => {
    setSelectedDateRange({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    })
  }

  return (
    <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
      <Grid className="shadow d-inline-block">
        <DateRangePicker
          onChange={handleSelect}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[selectedDateRange]}
          staticRanges={defaultStaticRanges}
          inputRanges={[]}
          direction="horizontal"
          fixedHeight={true}
          minDate={new Date('01-01-2015')}
          maxDate={new Date()}
          rangeColors={['#188295']}
          data-cy="dateRangePicker"
          // scroll={{enabled: true, calendarHeight: '240px'}}
        />
      </Grid>

      <Grid item>
        <CustomButtonGroup
          onClickClear={onClickClear}
          onClickApply={onClickApply}
        />
      </Grid>
    </Grid>
  )
}

DateRangeSelector.defaultProps = {
  ranges: defaultStaticRanges,
}

export default DateRangeSelector
