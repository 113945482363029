import { useState } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'

import {
  removeFilterValue,
  updateSearchPayload,
} from '../../../store/search/searchSlice'
import { FILTER_IS_ARCHIVED_KEY } from '../../../constants/search'
import CustomButtonGroup from '../../../components/CustomButtonGroup'
import { useSearchContext } from '../SearchContext'
import { BetaAnalytics } from '../../../../analytics/betaSearch'

const useStyles = makeStyles({
  archivalContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
  },
  archivalItem: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  checkbox: {
    '&.Mui-disabled': {
      color: '#00000061 !important',
    },
  },
})

function ArchivalAssetFilter({
  filterKey = '',
  closePopOver = () => {},
  availableFilters = {},
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { trackAnalyticsData = () => {} } = useSearchContext()

  const availabledFilteredValue =
    availableFilters?.[FILTER_IS_ARCHIVED_KEY]?.[0] || ''
  const [nonArchivedChecked, setNonArchivedChecked] = useState(
    availabledFilteredValue ? !JSON.parse(availabledFilteredValue) : true,
  )
  const [archivedChecked, setArchivedChecked] = useState(
    availabledFilteredValue ? JSON.parse(availabledFilteredValue) : true,
  )
  const onClickApply = () => {
    let archivalFilters = {}
    if (nonArchivedChecked && archivedChecked) {
      const filterObj = {
        group: filterKey,
        value: 'remove_filter',
      }
      dispatch(removeFilterValue(filterObj))
      trackAnalyticsData({
        eventType: BetaAnalytics.event.REMOVE_FILTER,
        eventName: BetaAnalytics.event.FILTER_IS_ARCHIVED,
        eventData: {
          key: FILTER_IS_ARCHIVED_KEY,
          value: availabledFilteredValue,
        },
      })
    } else {
      archivalFilters[filterKey] = [`${archivedChecked}`]
      dispatch(updateSearchPayload({ filters: archivalFilters }))
      trackAnalyticsData({
        eventType: BetaAnalytics.event.ADD_FILTER,
        eventName: BetaAnalytics.event.FILTER_IS_ARCHIVED,
        eventData: { key: FILTER_IS_ARCHIVED_KEY, value: archivedChecked },
      })
    }
    closePopOver()
  }

  return (
    <Grid container className={classes.archivalContainer}>
      <Grid container item className={classes.archivalItem}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={nonArchivedChecked}
                onChange={(event) =>
                  setNonArchivedChecked(event.target.checked)
                }
                value={nonArchivedChecked}
                size="small"
                disabled={!archivedChecked}
                className={classes.checkbox}
                data-cy={`nonArchivalCheckbox`}
              />
            }
            label={'Non Archived Assets'}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={archivedChecked}
                onChange={(event) => setArchivedChecked(event.target.checked)}
                value={archivedChecked}
                disabled={!nonArchivedChecked}
                className={classes.checkbox}
                size="small"
                data-cy={`archivalCheckbox`}
              />
            }
            label={'Archived Assets'}
          />
        </Grid>
      </Grid>
      <Grid item>
        <CustomButtonGroup onClickApply={onClickApply} hideClearButton={true} />
      </Grid>
    </Grid>
  )
}

export default ArchivalAssetFilter
