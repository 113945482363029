import React from 'react'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import IconWrapper from '../IconWrapper/IconWrapper'
import { FormikFieldPropTypes } from '../../constants/projects'

MomentUtils.prototype.getStartOfMonth = MomentUtils.prototype.startOfMonth

export const DateFormField = ({
  field = {},
  form = {},
  label = '',
  iconLabel = '',
  required = false,
  ...props
}) => (
  <IconWrapper iconTag={iconLabel} data-cy="datePicker">
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        PopoverProps={{ 'data-cy': 'datePickerPopover' }}
        leftArrowButtonProps={{ 'data-cy': 'datePickerLeft' }}
        rightArrowButtonProps={{ 'data-cy': 'datePickerRight' }}
        data-cy="datePicker"
        style={{ marginLeft: '20px' }}
        disableToolbar
        clearable
        label={label}
        name={field.name}
        error={!!form.errors[field.name]}
        leftArrowIcon={<KeyboardArrowLeft />}
        rightArrowIcon={<KeyboardArrowRight />}
        inputFormat="MM/dd/yyyy"
        minDate={new Date()}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            {...params}
            style={{ marginLeft: '10px' }}
            required={required}
            placeholder={`Enter ${label}`}
            InputLabelProps={{ shrink: true }}
            error={!field.value && !!form.errors[field.name]}
            helperText={!field.value && form.errors[field.name]}
            data-cy={`dateField_${field.name}`}
            {...field}
            {...props}
          />
        )}
        onChange={(value) => {
          form.setFieldValue(field.name, value)
        }}
        value={
          (field.value &&
          typeof field.value === 'string' &&
          field.value.indexOf('T') !== -1
            ? moment(new Date(field.value).toISOString().substring(0, 10))
                .utc()
                .local()
                .format('MMM DD, YYYY')
            : field.value) || null
        }
        {...props}
        autoOk={true}
      />
    </LocalizationProvider>
  </IconWrapper>
)

DateFormField.propTypes = FormikFieldPropTypes

export default DateFormField
