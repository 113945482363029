import React, { useState } from 'react'
import Downshift from 'downshift'
import { Paper, TextField, MenuItem } from '@mui/material'
import { FormikFieldPropTypes } from '../../constants/projects'
import IconWrapper from '../IconWrapper/IconWrapper'

export const getSuggestions = (suggestions = [], input = '') =>
  suggestions.filter((x) => x.toLowerCase().indexOf(input.toLowerCase()) !== -1)

export const AutocompleteFormField = ({
  form = {},
  field = {},
  isRequired = false,
  label = '',
  suggestions = [],
  iconLabel = '',
  optionalOnInputChange = () => {},
}) => {
  const [inputValue, setInputValue] = useState(field.value || '')
  const { value = '' } = field

  const handleChange = (item) => {
    const { name = '' } = field
    form.setFieldValue(name, item)
    setInputValue(item)
  }

  const handleInputChange = (event) => {
    optionalOnInputChange(event)
    return setInputValue(event.target.value)
  }

  return (
    <IconWrapper iconTag={iconLabel}>
      <Downshift
        inputValue={inputValue}
        selectedItem={value}
        onChange={handleChange}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          selectedItem,
          highlightedIndex,
        }) => (
          <div>
            <TextField
              variant="outlined"
              data-cy={`autocompleteField_${field.name}`}
              fullWidth
              label={label}
              placeholder={`Enter ${label}`}
              InputLabelProps={{ shrink: true }}
              required={isRequired}
              style={{ marginLeft: '10px' }}
              InputProps={getInputProps({ onChange: handleInputChange })}
            />
            {isOpen ? (
              <Paper style={{ maxHeight: '200px', overflowY: 'scroll' }} square>
                {getSuggestions(suggestions, inputValue).map(
                  (suggestion, index) => (
                    <MenuItem
                      {...getItemProps({ item: suggestion })}
                      key={suggestion}
                      selected={highlightedIndex === index}
                      component="div"
                      selectedItem={selectedItem}
                    >
                      {suggestion}
                    </MenuItem>
                  ),
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    </IconWrapper>
  )
}

AutocompleteFormField.propTypes = FormikFieldPropTypes

export default AutocompleteFormField
