import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'
import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import PopperMenu from '../../PopperMenu'
import AssetCardContext from './AssetCardContext'
import { checkIfUlimited } from '../../../../utils/search'
import { usageRightsInfoColumns } from '../../../../constants/search'
import { useAssetListContext } from '../AssetListContext'
import { useSearchContext } from '../../../Search/SearchContext'
import { checkUsageRightsExpiry } from '../../../../../helpers/usageRightsHelper'
import { isEmptyObject } from '../../../../../helpers/UtilityHelper'
import { BetaAnalytics } from '../../../../../analytics/betaSearch'

const styles = makeStyles((theme) => ({
  card: {
    width: '285px',
    height: '400px',
    borderRadius: '8px',
    position: 'relative',
    boxShadow: '#ececec 0px 0px 5px',
    border: '1px solid #f4f0f0',
    marginRight: 10,
  },
  fileName: {
    paddingBottom: '4px',
    paddingTop: '4px',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
    hyphens: 'auto',
    fontSize: 14,
    color: theme.palette.primary.primaryBlue,
    lineHeight: '16px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
    display: 'block',
  },
  asset: {
    height: '285px !important',
  },
  assetMedia: {
    borderBottom: '1px solid #f4f0f0',
    minHeight: '285px'
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px',
  },
  drmId: {
    fontSize: '12px',
    paddingBottom: '5px',
  },
  usageInfo: {
    padding: '10px',
  },
  usageText: {
    fontSize: '10px',
    paddingBottom: '3px',
  },
  drmButton: {
    width: '100px',
    height: '22px',
    fontSize: '12px',
    backgroundColor: '#188295',
    color: 'white',
    border: 'none',
  },
  limitedUseButton: {
    width: '110px',
    height: '22px',
    fontSize: '12px',
    backgroundColor: '#FAFAFA',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  limitedUseText: {
    color: 'rgba(223, 139, 72, 1)',
    display: 'flex',
    alignItems: 'center',
  },
  useIcon: {
    width: '20px',
    height: '20px',
    paddingRight: '3px',
    paddingLeft: '1px',
  },
  noUseButton: {
    width: '100px',
    height: '22px',
    fontSize: '12px',
    backgroundColor: '#FAFAFA',
    border: 'none',
    cursor: 'pointer',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  noUseText: {
    color: 'rgba(204, 0, 0, 1)',
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    paddingLeft: '10px',
  },
  infoBox: {
    paddingLeft: '0px !important',
  },
  urmLangText: {
    fontSize: '10px',
    textAlign: 'justify',
    overflow: 'auto',
    height: '100px',
    marginTop: '3px',
  },
  uploadDate: {
    fontSize: '14px',
  },
}))

const AssetCard = (props = {}) => {
  const classes = styles()
  const [drmOpen, setDrmOpen] = useState(false)
  const [currentDrm, setCurrentDrm] = useState(Number(0))
  const { asset = {}, isCardChecked, mediaComponent = '' } = props
  const { onSelection = () => {} } = useAssetListContext()
  const { trackAnalyticsData = () => {}, setRenderPage = () => {} } =
    useSearchContext()
  const {
    fileName = '',
    uploadedDate = '',
    creativeUsageInstructions = {},
    usageInfo = [],
    renderUrls: { downloadUrl = '', downloadPreviewUrl = '' } = {},
    assetId = '',
  } = asset || {}
  const { pathname: locationPath = '', search = '' } = useLocation()

  const openMetadataPage = async () => {
    await setRenderPage('ASSETMETADATA')
    await window.history.pushState(
      {
        consumer: 'Search',
        returnUrl: search ? `${locationPath}${search}` : `${locationPath}`,
        isArrowShow: true,
        assetId: assetId,
        location: locationPath,
      },
      'metadata',
      `${locationPath}/${assetId}/metadata`,
    )
  }

  const renderFileName = () => (
    <Tooltip
      title={fileName}
      placement="top-start"
      disableHoverListener={fileName?.length <= 30 ? true : false}
    >
      <a
        data-cy={`assetCardLink`}
        alt={fileName}
        style={{ cursor: 'pointer' }}
        onClick={openMetadataPage}
        className={classes.fileName}
        href={() => false}
      >
        {fileName}
      </a>
    </Tooltip>
  )

  const renderCardHeader = () => (
    <Grid container alignItems={'baseline'}>
      <Grid item xs={2}>
        <Checkbox
          checked={isCardChecked}
          onChange={(event) => {
            onSelection(event, asset)
          }}
          id={assetId}
          inputProps={{ 'aria-label': 'controlled' }}
          data-cy={`assetSelection`}
        />
      </Grid>
      <Grid item xs={10}>
        <Grid container direction={'column'}>
          <Grid item>{renderFileName()}</Grid>
          <Grid item className={classes.uploadDate}>
            {uploadedDate}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  const onDrmClick = () => {
    setDrmOpen(!drmOpen)
  }

  const nextDrm = () => {
    if (currentDrm < usageInfo?.length - 1) {
      setCurrentDrm(currentDrm + 1)
    }
  }

  const prevDrm = () => {
    if (currentDrm > Number(0)) {
      setCurrentDrm(currentDrm - 1)
    }
  }

  const donotUseText = () => (
    <>
      <ErrorIcon className={classes.useIcon} data-cy='donotuse'></ErrorIcon>
      {'Do Not Use'}
    </>
  )

  const expiredText = () => (
    <>
      <WarningIcon className={classes.useIcon} data-cy='expired'/>
      Expired
    </>
  )

  const limitedUseText = () => (
    <>
      <ErrorIcon className={classes.useIcon} data-cy='limited'></ErrorIcon>
      {'Limited Use'}
    </>
  )

  const drmButtonTextByType = (type = '') => {
    let renderText = ''
    switch (type) {
      case 'donotuse':
        renderText = donotUseText()
        break
      case 'expired':
        renderText = expiredText()
        break
      case 'limiteduse':
        renderText = limitedUseText()
        break
      default:
        break
    }
    return renderText
  }

  const getDrmButtonByType = (type = '') => {
    const renderText = drmButtonTextByType(type)
    return (
      <button
        onClick={onDrmClick}
        className={
          creativeUsageInstructions?.doNotUse
            ? classes.noUseButton
            : classes.limitedUseButton
        }
      >
        <div
          className={
            creativeUsageInstructions?.doNotUse
              ? classes.noUseText
              : classes.limitedUseText
          }
        >
          {renderText}
        </div>
      </button>
    )
  }

  const getDrmTitleByType = (type = '') => (
    <div
      className={
        creativeUsageInstructions?.doNotUse
          ? classes.noUseText
          : classes.limitedUseText
      }
    >
      {drmButtonTextByType(type)}
    </div>
  )

  const renderDrmButtonOnClose = () => (
    <>
      {!isEmptyObject(creativeUsageInstructions) &&
      creativeUsageInstructions?.doNotUse
        ? getDrmButtonByType('donotuse')
        : checkUsageRightsExpiry(usageInfo)
          ? getDrmButtonByType('expired')
          : !checkIfUlimited(usageInfo)
            ? getDrmButtonByType('limiteduse')
            : ''}
    </>
  )

  const renderDrmLayoutHeader = () => {
    const { doNotUse = false } = creativeUsageInstructions || {}
    return (
      <Grid
        container
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item>
          {doNotUse ? (
            getDrmTitleByType('donotuse')
          ) : checkUsageRightsExpiry(usageInfo) ? (
            getDrmTitleByType('expired')
          ) : !checkIfUlimited(usageInfo) ? (
            getDrmTitleByType('limiteduse')
          ) : (
            <></>
          )}
        </Grid>
        <Grid item>
          <IconButton onClick={onDrmClick} size="small" data-cy='closeIconButton'>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  const renderUsageInstructions = () => {
    const { instructions, additionalDirections } =
      creativeUsageInstructions || {}
    return (
      <div className={classes.usageContainerCreative}>
        <div className={classes.usageInfo}>
          <div className={classes.usageText}>
            <b>Creative Usage Instructions: </b>
            {instructions ? instructions : 'Information not provided'}
          </div>
          <div className={classes.usageText}>
            <b>Additional Directions: </b>
            {additionalDirections
              ? additionalDirections
              : 'Information not provided'}
          </div>
        </div>
      </div>
    )
  }

  const renderUsageInfoDetails = () => {
    const usageRightsInfo = Object.assign({}, usageInfo[currentDrm])
    const usageRightsInfoObj = Object.assign({}, usageRightsInfo, {
      urmLanguage: usageRightsInfo?.customUsageRights
        ? usageRightsInfo?.customUsageRightsLanguage
        : usageRightsInfo?.usageRightsLanguage,
    })
    const returnText = usageRightsInfoColumns?.map((curColumn = {}) => (
      <Grid
        container
        className={classes[curColumn?.class]}
        style={{ padding: '1px 6px' }}
        spacing={1}
      >
        <Grid item style={{ fontWeight: 600 }}>
          {`${curColumn?.label + ' : '} `}
        </Grid>
        <Grid item>{`${usageRightsInfoObj[curColumn?.id]}`}</Grid>
      </Grid>
    ))
    return returnText
  }

  const renderDrmLayoutFooter = () =>
    usageInfo?.length > 1 ? (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems={'center'}
        className={classes.drmNav}
      >
        <IconButton
          onClick={() => prevDrm()}
          className={classes.navButton}
          size="large"
          data-cy='prevClick'
        >
          <ArrowForwardIosIcon
            style={{ transform: 'scaleX(-1)' }}
            className={classes.navButton}
          />
        </IconButton>
        <div className={classes.navText}>
          {currentDrm + 1} of {usageInfo.length}
        </div>
        <IconButton
          onClick={() => nextDrm()}
          className={classes.navButton}
          size="large"
          data-cy='nextClick'
        >
          <ArrowForwardIosIcon className={classes.navButton} />
        </IconButton>
      </Grid>
    ) : (
      <div />
    )

  const trackSingleDownloadClick = (downloadResolution = '') => {
    trackAnalyticsData({
      eventType: BetaAnalytics.event.ASSET_USAGE,
      eventName: BetaAnalytics.event.DOWNLOAD_ASSET,
      eventData: {
        asset,
        downloadResolution,
        downloadStyle: 'SINGLE_ASSET_CARD',
      },
    })
  }

  const renderPopperMenu = () => (
    <PopperMenu
      downloadLink={downloadUrl}
      downloadPreviewLink={downloadPreviewUrl}
      trackSingleDownloadClick={trackSingleDownloadClick}
    />
  )

  const renderDrmViewLayout = () => {
    const { doNotUse = false } = creativeUsageInstructions || {}

    return (
      <Grid container direction={'column'} spacing={1}>
        <Grid item xs={12} lg={12} md={12}>
          {renderDrmLayoutHeader()}
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          {doNotUse ? renderUsageInstructions() : renderUsageInfoDetails()}
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          {renderDrmLayoutFooter()}
        </Grid>
      </Grid>
    )
  }
  const renderDrmCloseLayout = () => (
    <Grid container direction={'column'}>
      <Grid item className={classes.assetMedia}>
        {mediaComponent}
      </Grid>
      <Grid item>
        <Grid
          className={classes.cardFooter}
          container
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Grid item>{renderDrmButtonOnClose()}</Grid>
          <Grid item>{renderPopperMenu()}</Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  const renderCardContent = () =>
    !drmOpen ? (
      renderDrmCloseLayout()
    ) : usageInfo.length > 0 || creativeUsageInstructions?.doNotUse ? (
      renderDrmViewLayout()
    ) : (
      <></>
    )

  return (
    <AssetCardContext.Provider
      value={{ asset: asset, isCardChecked: isCardChecked, onSelection }}
    >
      <Card sx={{ maxWidth: 345 }} className={classes.card}>
        <CardActions
          // disableSpacing
          style={{ borderBottom: '1px solid #f4f0f0' }}
        >
          {renderCardHeader()}
        </CardActions>
        <>{renderCardContent()}</>
      </Card>
    </AssetCardContext.Provider>
  )
}

export default AssetCard
