import React, { useState, createRef } from 'react'
import { useSelector } from 'react-redux'
import Dropzone from 'react-dropzone'
import { useEnv } from '@praxis/component-runtime-env'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import CloudIcon from '@mui/icons-material/Cloud'
import Publish from '@mui/icons-material/Publish'

const dropzoneRef = createRef()

const useStyles = makeStyles({
  card: {
    backgroundColor: '#FAFAFA',
    width: '100%',
    height: '100%',
  },
  assetCardContent: {
    minHeight: '45vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed grey',
  },
  uploadButton: {
    backgroundColor: '#188295',
    color: '#FFFFFF',
    padding: '15px 30px',
    letterSpacing: '0px',
    '&:hover': {
      backgroundColor: '#002171',
    },
  },
  disableButton: {
    padding: '15px 30px',
    letterSpacing: '0px',
  },
  dropDesc: {
    margin: '10px',
    fontSize: '14px',
  },
})

function ClassificationUploadManager(props) {
  const classes = useStyles()
  const { projectName = '', acceptedFiles = {}, onDropFiles = () => {} } = props
  const { selectedClassificationId = '', selectedClassification = '' } =
    useSelector((state) => state.assetClassificationReducer) || {}
  const [dropZoneActive, setDropZoneActive] = useState(false)
  const disableUpload =
    !selectedClassificationId &&
    selectedClassification !== 'Upload without classification'

  const onDragEnter = () => {
    setDropZoneActive(true)
  }

  const onDragLeave = () => {
    setDropZoneActive(false)
  }

  const openSystemDialog = () => {
    if (dropzoneRef && dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }

  const onDrop = (files) => {
    const { handleClose = () => {} } = props
    setDropZoneActive(false)
    onDropFiles(files)
    handleClose()
  }

  const onDropRejected = () => {
    setDropZoneActive(false)
  }

  return (
    <Dropzone
      noClick
      disabled={disableUpload}
      style={{ position: 'relative', width: '100%', height: '100%' }}
      accept={acceptedFiles}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDropRejected={onDropRejected}
      minSize={1000}
      ref={dropzoneRef}
      data-cy='drag-and-drop'
    >
      {({ getRootProps, getInputProps }) => (
        <section className={classes.dropzone}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <>
              <Card className={classes.card}>
                <CardContent className={classes.assetCardContent}>
                  {dropZoneActive && (
                    <div className={classes.uploadOverlay}>
                      <div className={classes.ionContainer}>
                        <div style={{ position: 'relative' }}>
                          <CloudIcon className={classes.largeIcon} />
                          <div className={classes.iconContent}>
                            <div>
                              <Publish className={classes.publishIcon} />
                            </div>
                            <div>Upload to:</div>
                            <div style={{ fontSize: 16, color: '#0d46a0' }}>
                              {projectName}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {
                    <div>
                      <Button
                        onClick={openSystemDialog}
                        color="primary"
                        aria-label="upload"
                        variant="contained"
                        data-cy='uploadAssetsButtonInManager'
                        className={
                          disableUpload
                            ? classes.disableButton
                            : classes.uploadButton
                        }
                        disabled={disableUpload}
                      >
                        Upload Assets
                      </Button>
                      <div className={classes.dropDesc}>
                        or drag and drop files
                      </div>
                    </div>
                  }
                </CardContent>
              </Card>
            </>
          </div>
        </section>
      )}
    </Dropzone>
  )
}

export default ClassificationUploadManager
