import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import { deburr, get, isEmpty } from 'lodash'
import momentTime from 'moment-timezone'
import match from 'autosuggest-highlight/match/index'
import parse from 'autosuggest-highlight/parse/index'

import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import MomentUtils from '@date-io/moment'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Paper from '@mui/material/Paper'

import { InputAdornment } from '@mui/material'

import Clear from '@mui/icons-material/Clear'
import Comment from '@mui/icons-material/Comment'
import DateRange from '@mui/icons-material/DateRange'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import FolderSpecial from '@mui/icons-material/FolderSpecial'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { editProjectTask } from './../../store/task/actionCreator'
import {
  REVIEW_TASK_TYPE_ID,
  TASK_TYPE_VALUE_PARAM_MAP,
} from '../../constants/projectTask'
import { fieldValidRegex } from '../../helpers/Regexes'
import {
  getDefaultTaskType,
  getSelectedTaskType,
  getTaskTypes,
} from '../../helpers/TaskHelper'
import { TEXT_SPECIAL_CHARS_FORM_VALID } from '../../constants/notifications'
import withRouter from '../../containers/Router/WithRouter'

MomentUtils.prototype.getStartOfMonth = MomentUtils.prototype.startOfMonth

function renderInputComponent(inputProps = {}) {
  const {
    value = '',
    classes = {},
    ref = () => {},
    inputRef = () => {},
    clearSelectedTaskName = () => {},
    ...other
  } = inputProps
  const isError = (value.match(fieldValidRegex) || []).length > 0

  return (
    <TextField
      variant="standard"
      fullWidth
      error={isError}
      helperText={isError && TEXT_SPECIAL_CHARS_FORM_VALID}
      InputProps={{
        inputRef: (node) => {
          ref(node)
          inputRef(node)
        },
        classes: {
          input: classes.input,
        },
        endAdornment: (
          <InputAdornment
            style={{ display: value.length > 0 ? 'inherit' : 'none' }}
            position="end"
          >
            <Clear onClick={clearSelectedTaskName} />
          </InputAdornment>
        ),
      }}
      value={value}
      {...other}
    />
  )
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.task_name, query)
  const parts = parse(suggestion.task_name, matches)

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
      </div>
    </MenuItem>
  )
}

function getSuggestions(suggestions, value) {
  let returnValue = []
  if (suggestions) {
    const inputValue = deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length
    let count = 0
    if (inputLength != 0) {
      returnValue = suggestions.filter((suggestion) => {
        const keep =
          count < 5 &&
          suggestion.task_name.slice(0, inputLength).toLowerCase() ===
            inputValue
        if (keep) {
          count += 1
        }
        return keep
      })
    } else {
      returnValue = [...suggestions]
    }
  }
  return returnValue
}

function getCalcDate(day, week) {
  let today = momentTime().isoWeekday()
  return today < day
    ? momentTime().isoWeekday(day).format('MM/DD/YYYY')
    : momentTime().add(week, 'weeks').isoWeekday(day).format('MM/DD/YYYY')
}

const styles = makeStyles((theme) => ({
  margin: {
    margin: useTheme().spacing(),
  },
  textField: {
    flexBasis: 200,
  },
  emptyGroup: {
    border: '1px dashed #c5c5c5',
    marginTop: 20 + ' px',
    textAlign: 'center',
    padding: '15% 0',
  },
  leftIcon: {
    marginRight: useTheme().spacing(),
  },
  groupListWrapper: {
    minHeight: 300,
    marginTop: 10,
  },
  wrapper: {
    margin: useTheme().spacing(),
    position: 'relative',
  },
  button: {
    backgroundColor: '#0D46A0',
    '&:hover': {
      backgroundColor: '#0D46A0',
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  AutoCompleteWarpper: {
    backgroundColor: '#f7f7f7',
    border: '1px solid #EFEFEF',
    paddingBottom: 8,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 2,
    marginTop: useTheme().spacing(),
    left: 0,
    right: 0,
    overflowX: 'hidden',
    overflowY: 'scroll',
    maxHeight: '130px',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  paddingRight: {
    paddingRight: 25,
    width: '175%',
  },
  paddingRightTime: {
    paddingRight: 10,
  },
  datePickerWidth: {
    width: '150px',
  },
}))

export class AddTask extends React.Component {
  constructor(props) {
    super(props)
    this.isChanged = false
    let myDate = momentTime(new Date()).format('MM/DD/YYYY')
    let myTime = momentTime(new Date().setHours(0, 0, 0, 0)).format('HH:mm')
    let selectedTaskType = getDefaultTaskType()
    this.datePickerDate = new Date().setHours(17, 0, 0, 0)
    const { editInfo = {} } = props
    const {
      due_date,
      job_name = '',
      task_type = REVIEW_TASK_TYPE_ID,
    } = editInfo
    if (due_date) {
      this.datePickerDate = due_date
      this.isChanged = true
    }
    let isEdit = isEmpty(editInfo) ? false : true
    if (isEdit) {
      myDate = momentTime(new Date(due_date)).format('MM/DD/YYYY')
      myTime = momentTime(new Date(due_date)).format('HH:mm')
      selectedTaskType = getSelectedTaskType(task_type)
    }

    this.state = {
      task: {
        taskTypes: getTaskTypes(),
        selectedTaskType,
        selectedTaskName: job_name,
        scheduledDate: myDate,
        scheduledTime: myTime,
      },
      project: {
        name:
          get(props, 'task.taskList.userAssets.project_name', '') ||
          get(props, 'router.location.state.project.name', ''),
        type:
          get(props, 'task.taskList.userAssets.project_type', '') ||
          get(props, 'router.location.state.project.type', ''),
        projectUuid:
          get(props, 'task.taskList.userAssets.project_uuid', '') ||
          get(props, 'router.location.state.project.projectUuid', ''),
        projectId:
          get(props, 'task.taskList.userAssets.project_id', '') ||
          get(props, 'router.location.state.project.projectId', ''),
        runDate:
          get(props, 'task.taskList.userAssets.run_date', '') ||
          get(props, 'router.location.state.project.runDate', ''),
        dueDate:
          get(props, 'task.taskList.userAssets.due_date', '') ||
          get(props, 'router.location.state.project.dueDate', ''),
        uoiId:
          get(props, 'task.taskList.userAssets.uoi_id', '') ||
          get(props, 'router.location.state.project.uoiId', ''),
        reviewJobId:
          get(props, 'task.taskList.userAssets.review_job_id', '') ||
          get(props, 'router.location.state.project.reviewJobId', ''),
      },
      suggestions: [],
      isEdit,
      editTaskInfo: isEdit ? editInfo : {},
    }
  }

  handleChange = (event, prop = '') => {
    this.setState(
      {
        task: { ...this.state.task, [prop]: event.target.value },
      },
      () => {
        this.clearSelectedTaskName()
      }
    )
  }

  getSuggestionValue = (suggestion = {}) => {
    if (!isEmpty(suggestion)) {
      const { due_day_week_num = 0, due_time = '', task_name = '' } = suggestion
      if (due_day_week_num !== null) {
        let week_num = parseInt(suggestion.due_week_num, 10)
        let getDate = new Date(getCalcDate(due_day_week_num, week_num))
        this.isChanged = true // Marking as true all the time when date is populated.
        if (due_day_week_num !== 0) {
          this.datePickerDate = getDate
        } else {
          this.datePickerDate = new Date(this.datePickerDate)
          this.isChanged = false
        }
        let HoursMins =
          due_time.indexOf(':') !== -1 ? due_time.split(':') : due_time
        this.datePickerDate.setHours(
          HoursMins.length > 0 ? HoursMins[0] : due_time,
          HoursMins.length > 0 ? HoursMins[1] : 0,
          0,
          0
        )
        setTimeout(() => {
          this.setState((prevState) => ({
            task: {
              ...prevState.task,
              scheduledDate: momentTime(this.datePickerDate).format(
                'MM/DD/YYYY'
              ),
              scheduledTime: momentTime(this.datePickerDate).format('HH:mm'),
            },
          }))
        })
      }
      return task_name
    }
    return ''
  }

  handleDateChange = (date) => {
    this.datePickerDate = new Date(date)
    const selectedDate = new Date(date)
    this.isChanged = true
    this.setState({
      task: {
        ...this.state.task,
        scheduledDate: momentTime(selectedDate).format('MM/DD/YYYY'),
        scheduledTime: momentTime(selectedDate).format('HH:mm'),
      },
    })
  }

  handleTimeChange = (e) => {
    const time = e.target.value

    if (time) {
      this.setState((state) => ({
        task: {
          ...state.task,
          scheduledTime: time,
        },
      }))
    }
  }

  handleSuggestionsFetchRequested = ({ value = '' }) => {
    if (!value) this.isChanged = false
    let options = this.props.task.taskList.catalog
    this.setState({
      suggestions: getSuggestions(options, value),
    })
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  handleChangeAuto =
    (name) =>
    (event, { newValue }) => {
      this.refs.autosuggest.props.inputProps.value = newValue
      this.refs.autosuggest.input.value = newValue
      this.setState({
        [name]: newValue,
        task: { ...this.state.task, [name]: newValue },
      })
    }
  createTask = () => {
    const { history = {}, match = {}, router = {}, onClose } = this.props
    const { params = {} } = router
    const { projectId = '' } = params
    const { editTaskInfo = {}, isEdit, task = {} } = this.state
    const taskType = TASK_TYPE_VALUE_PARAM_MAP[task.selectedTaskType]
    let url = `/project/${projectId}/tasks/create?taskType=${taskType}`
    if (isEdit) {
      url = `/project/${projectId}/tasks/edit/${editTaskInfo.job_id}?taskType=${taskType}`
    }
    //TODO Here Task is object, no meaning to check length but removing that logic leads to other erros, not sure why, need to revisit.
    this.props.router.navigate(url, {
      state: { task: task.length ? task : this.state },
    })
    onClose()
  }

  renderLabel = (date) => {
    if (date.isValid() && this.isChanged) {
      return date.format('MM/DD/YYYY')
    } else {
      return ''
    }
  }

  clearSelectedTaskName = () => {
    this.refs.autosuggest.props.inputProps.value = ''
    this.refs.autosuggest.input.value = ''
    this.refs.autosuggest.input.focus()
    this.handleSuggestionsFetchRequested({ value: '' })
    this.setState((state) => ({
      selectedTaskName: '',
      task: {
        ...state.task,
        selectedTaskName: '',
        scheduledTime: momentTime(new Date().setHours(0, 0, 0, 0)).format(
          'HH:mm'
        ),
      },
    }))
  }

  render() {
    const { classes = {}, onClose = () => {}, open, task = {} } = this.props
    const { loading = {} } = task
    const { taskCreateLoading } = loading
    const { isEdit = false, task: stateTask, suggestions = [] } = this.state
    const {
      selectedTaskType = '',
      selectedTaskName = '',
      scheduledDate = '',
      scheduledTime = '',
      taskTypes = [],
    } = stateTask || {}
    const canDisableButton = !(
      selectedTaskType &&
      selectedTaskName &&
      scheduledDate &&
      scheduledTime
    )
    const autosuggestProps = {
      renderInputComponent,
      suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion,
    }
    return (
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        fullWidth
      >
        <AppBar className="dialogTitleBar">
          <Toolbar className="dialogTitleHeight">
            <Typography
              data-cy="taskDialog"
              color="inherit"
              className="dialogTitle"
            >
              {isEdit ? 'Edit A Task' : 'Create A Task'}
            </Typography>
            <IconButton
              className="dialogClose"
              aria-label="Delete"
              onClick={onClose}
              size="large"
            >
              <Clear />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ width: 520 }}>
          <form style={{ paddingTop: 20 }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item>
                <FolderSpecial style={{ marginTop: 20 }} />
              </Grid>
              <Grid item xs={11} style={{ marginLeft: 10 }}>
                <InputLabel htmlFor="selectedAssetTypes">
                  Select Task Type {isEdit}
                </InputLabel>
                <Select
                  variant="standard"
                  data-cy="selectTaskType"
                  disabled={isEdit}
                  value={selectedTaskType}
                  onChange={(event) =>
                    this.handleChange(event, 'selectedTaskType')
                  }
                  inputProps={{
                    name: 'selectedAssetTypes',
                  }}
                  fullWidth
                >
                  {taskTypes.map((type, index) => (
                    <MenuItem data-cy={type} value={type} key={{ index }}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <Comment style={{ marginTop: 20 }} />
              </Grid>
              <Grid item xs={11} style={{ marginLeft: 10, marginTop: 10 }}>
                <Autosuggest
                  {...autosuggestProps}
                  ref="autosuggest"
                  shouldRenderSuggestions={() => true}
                  inputProps={{
                    classes,
                    'data-cy': 'taskName',
                    placeholder: 'Enter Task Name',
                    value: selectedTaskName,
                    onChange: this.handleChangeAuto('selectedTaskName'),
                    clearSelectedTaskName: this.clearSelectedTaskName,
                  }}
                  data-cy='taskName'
                  theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                  }}
                  renderSuggestionsContainer={(options) => (
                    <Paper {...options.containerProps} square>
                      {options.children}
                    </Paper>
                  )}
                />
              </Grid>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
              >
                <Grid item xs={6}>
                  <Grid container direction="row" justifyContent="flex-start">
                    <Grid item style={{ alignSelf: 'flex-end' }}>
                      <DateRange style={{ marginTop: 20 }} />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        marginLeft: 18,
                        marginTop: 10,
                        minWidth: '150px',
                      }}
                    >
                      <DesktopDatePicker
                        disableToolbar
                        data-cy="taskDueDate"
                        label="Enter Due Date & Time"
                        value={this.datePickerDate}
                        onChange={this.handleDateChange}
                        leftArrowIcon={<KeyboardArrowLeft />}
                        rightArrowIcon={<KeyboardArrowRight />}
                        inputFormat="MM/dd/yyyy"
                        minDate={new Date()}
                        labelFunc={this.renderLabel}
                        autoOk={true}
                        renderInput={(params) => (
                          <TextField
                            className={classes.datePickerWidth}
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <AccessTimeIcon />
                  <form noValidate>
                    <TextField
                      variant="standard"
                      data-cy="taskDueTime"
                      onChange={this.handleTimeChange}
                      style={{ padding: '0 10px' }}
                      type="time"
                      value={scheduledTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </form>
                  <span style={{ alignSelf: 'flex-end' }}>CST</span>
                </Grid>
                <div style={{ fontSize: 12, margin: '15px 0px 10px 40px' }}>
                  Upon this deadline, all reviewers will be locked from adding
                  additional comments. All admins will still be able to add
                  comments after the deadline.
                </div>
              </Grid>
            </LocalizationProvider>
          </form>
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              data-cy="taskContinueButton"
              variant="contained"
              className={classes.button}
              onClick={() => this.createTask(this)}
              color="primary"
              disabled={canDisableButton}
            >
              Continue To Details{' '}
              <KeyboardArrowRight className={classes.leftIcon} />
            </Button>
            {taskCreateLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

AddTask.propTypes = {
  classes: PropTypes.object.isRequired,
  editInfo: PropTypes.object,
  task: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
const mapStateToProps = (state) => {
  const { layout } = state
  const { headerTitle } = layout
  const auth = state.auth
  let { task } = state
  return {
    headerTitle: headerTitle,
    auth: auth,
    task: task,
  }
}

const MyComponent = (props) => {
  const classes = styles()
  return <AddTask {...props} classes={classes} />
}

export default connect(mapStateToProps, { editProjectTask })(
  withRouter(MyComponent)
)
