import { Button, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles({
  buttonGroup: {
    borderTop: '1px solid #f4f0f0',
    width: '100%',
    padding: '15px',
    display: 'flex',
    gap: '15px',
    justifyContent: 'flex-end',
  },
  actionButton: {
    '&:hover': {
      backgroundColor: '#115E6C',
      color: '#fff',
    },
  },
})

function CustomButtonGroup({
  onClickClear = () => {},
  onClickApply = () => {},
  isClearDisabled = false,
  isApplyDisabled = false,
  hideClearButton = false,
}) {
  const classes = useStyles()
  return (
    <Grid container className={classes.buttonGroup}>
      {hideClearButton ? (
        ''
      ) : (
        <Button
          variant="outlined"
          color="secondary"
          type="submit"
          onClick={() => onClickClear()}
          disabled={isClearDisabled}
          className={classes.actionButton}
          data-cy={'clearAll'}
        >
          Clear All
        </Button>
      )}
      <Button
        variant="outlined"
        color="secondary"
        type="submit"
        disabled={isApplyDisabled}
        onClick={() => onClickApply()}
        className={classes.actionButton}
        data-cy={'apply'}
      >
        Apply
      </Button>
    </Grid>
  )
}

export default CustomButtonGroup
