import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Search as SearchIcon } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { updateSearchTermWithType } from '../../../store/search/searchSlice'
import {
  searchTypeAddonsForAdmins,
  searchTypeListForUsers,
} from '../../../constants/search'
import { useSearchContext } from '../SearchContext'
import { BetaAnalytics } from '../../../../analytics/betaSearch'

const useStyles = makeStyles({
  colorSwitchBase: {
    color: '#188295',
    '&.Mui-checked ': {
      color: '#188295',
    },
  },
  exactSearchGrid: {
    paddingLeft: '10px',
    display: 'flex',
    alignItems: 'center'
  },
  textGrid: {
    display: 'flex',
    padding: '20px 0px',
  },
  searchTextField: {
    width: '50vw',
    minHeight: '40px',
    background: 'white',
  },
  searchButton: {
    borderRadius: '4px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#115E6C',
    },
  },
  searchTypeField: {
    minWidth: '140px',
    backgroundColor: '#61514226',
  },
  adornedStartStyle: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
})

function SearchInputBox() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { trackAnalyticsData = () => { } } = useSearchContext()
  const {
    searchTerm = '',
    searchType = '',
    strictSearch: storeStrictSearch = false,
    isAdmin = false,
    isLibrarian = false,
  } = useSelector((state) => ({
    searchTerm: state?.newSearch?.searchTerm || '',
    searchType: state?.newSearch?.searchType || '',
    strictSearch: state?.newSearch?.strictSearch || false,
    isAdmin: state?.auth?.isAdGroupSuperAdmin || '',
    isLibrarian: state?.auth?.isLibrarian || '',
  }))
  const [textSearchValue, setTextSearchValue] = useState(searchTerm)
  const [searchTypeValue, setSearchTypeValue] = useState(searchType)
  const [strictSearch, setStrictSearch] = useState(storeStrictSearch)

  const triggerExactSearch = (exactSearchVal) => {
    setStrictSearch(exactSearchVal)
  }

  const onSearchClick = () => {
    dispatch(
      updateSearchTermWithType({
        searchTerm: textSearchValue,
        searchType: searchTypeValue,
        strictSearch: strictSearch,
      }),
    )
    trackAnalyticsData({
      eventType: BetaAnalytics.event.ADD_FILTER,
      eventName: BetaAnalytics.event.SEARCH_TEXT,
      eventData: { searchTerm: textSearchValue, searchType: searchTypeValue },
    })
    const navigationUrl = textSearchValue? `?type=${searchTypeValue}&term=${textSearchValue}&strict=${strictSearch}` : ''
    navigate(navigationUrl)
  }

  useEffect(() => {
    setTextSearchValue(searchTerm)
  }, [searchTerm])

  useEffect(() => {
    setSearchTypeValue(searchType)
  }, [searchType])

  useEffect(() => {
    onSearchClick(strictSearch)
  }, [strictSearch])

  useEffect(() => {
    setStrictSearch(storeStrictSearch)
  }, [storeStrictSearch])


  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSearchClick()
    }
  }

  const handleSearchTypeChange = (searchType = '') => {
    setSearchTypeValue(searchType)
  }

  const searchTypeList =
    isAdmin || isLibrarian ? searchTypeListForUsers.concat(searchTypeAddonsForAdmins) : searchTypeListForUsers

  return (
    <Grid container className={classes.searchGrid}>
      <Grid item xs={8} md={12} lg={12} className={classes.textGrid}>
        <FormControl>
          <TextField
            size="small"
            id="searchTerm"
            variant="outlined"
            data-cy={'searchTerm'}
            className={classes.searchTextField}
            value={textSearchValue}
            onChange={(event) => {
              setTextSearchValue(event.target.value)
            }}
            autoFocus
            onKeyDown={(e) => handleKeyDown(e)}
            InputProps={{
              className: classes.adornedStartStyle,
              startAdornment: (
                <FormControl size="small" style={{ paddingRight: '30px' }}>
                  <Select
                    labelId="modelused-label"
                    id="modelused-label"
                    data-cy={'selectType'}
                    size="small"
                    value={searchTypeValue}
                    onChange={(event) =>
                      handleSearchTypeChange(event.target.value)
                    }
                    className={classes.searchTypeField}
                  >
                    {searchTypeList.map((searchTypeItem) => (
                      <MenuItem value={searchTypeItem.value} data-cy={searchTypeItem.value}>
                        {searchTypeItem.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ),
            }}
          />
        </FormControl>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          data-cy={'searchClickButton'}
          className={classes.searchButton}
          onClick={() => onSearchClick()}
        >
          <SearchIcon className={classes.searchIcon} />
        </Button>
        <Grid className={classes.exactSearchGrid}>
          <FormGroup>
            <FormControlLabel control={<Switch checked={strictSearch}
              data-cy={'strictSwitch'}
              disabled={textSearchValue === ''}
              classes={{
                switchBase: classes.colorSwitchBase,
              }}
              onChange={(event) => triggerExactSearch(event.target.checked)} />} label="Exact Phrase Match" />
          </FormGroup>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SearchInputBox
