import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import { PhotoCamera } from '@mui/icons-material'
import { useSearchContext } from '../SearchContext'

const useStyles = makeStyles({
  searchByImageButton: {
    color: 'white',
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#115E6C',
    },
  },
})

function ReverseImageSearch() {
  const { setImageSearchDialogOpen = () => {} } = useSearchContext()
  const classes = useStyles()

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => {
        setImageSearchDialogOpen(true)
      }}
      className={classes.searchByImageButton}
      startIcon={<PhotoCamera className={classes.cameraIcon} />}
      data-cy={`searchByImages`}
    >
      Search By Images
    </Button>
  )
}

export default ReverseImageSearch
