import React from 'react'
import { makeStyles } from '@mui/styles'
import ReactImageMagnify from 'react-image-magnify'

import NoThumbnail from '../../../../../images/NoThumbnail.svg?url'
import { useAssetCardContext } from './AssetCardContext'

const styles = makeStyles((theme) => ({
  asset: {
    height: '285px !important',
  },
}))

const AssetImageCard = (props) => {
  const classes = styles()
  const { asset = [] } = useAssetCardContext()
  const { fileName = '', renderUrls = {} } = asset

  return (
    <ReactImageMagnify
      smallImage={{
        isFluidWidth: true,
        alt: fileName,
        src: renderUrls?.previewThumbnail
          ? renderUrls?.previewThumbnail
          : NoThumbnail,
        height: 500,
        width: 500,
        onError: (event) => (event.target.src = NoThumbnail),
      }}
      largeImage={{
        alt: fileName,
        src: renderUrls?.previewHoverThumbnail,
        width: 1000,
        height: 1000,
      }}
      enlargedImagePosition="over"
      className={classes.asset}
      data-cy='assetImageMagnifyCard'
    />
  )
}

export default AssetImageCard
