import { FormGroup } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useState, useEffect } from 'react'
import CheckboxItem from './CheckboxItem'

const useStyles = makeStyles({
  checkboxGroup: {
    backgroundColor: '#d9d9d9c7',
    paddingLeft: '2px',
  },
  checkboxChild: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '2px',
  },
  childItem: {
    height: '10px',
    paddingLeft: '2px',
  },
})

function CheckboxGroup({
  parentCheckboxLabel = '',
  parentCheckboxValue = '',
  childItems = [],
  selectedFilterValues = [],
  clearAllItems,
  // shouldUpdateParent,
  updateParentState = () => {},
  uniqueIdentifier = 'value',
}) {
  const classes = useStyles()
  const [parentChecked, setParentChecked] = useState(false)
  const [childChecked, setChildChecked] = useState([])

  useEffect(() => {
    let initialCheckedItems = []
    childItems.forEach((item) => {
      if (selectedFilterValues.includes(item[uniqueIdentifier])) {
        initialCheckedItems.push(item[uniqueIdentifier])
      }
    })
    setChildChecked(initialCheckedItems)
  }, [])

  useEffect(() => {
    if (childChecked.length === childItems.length) {
      setParentChecked(true)
    } else {
      setParentChecked(false)
    }
    updateParentState({
      filterKey: parentCheckboxValue,
      selectedValues: childChecked,
    })
  }, [childChecked])

  useEffect(() => {
    if (clearAllItems) {
      setChildChecked([])
      setParentChecked(false)
    }
  }, [clearAllItems])

  // useEffect(() => {
  //   shouldUpdateParent &&
  //     updateParentState({
  //       filterKey: parentCheckboxValue,
  //       selectedValues: childChecked,
  //     })
  // }, [shouldUpdateParent])

  const handleParentCheckboxChange = (checkStatus) => {
    setParentChecked(checkStatus)
    if (checkStatus) {
      setChildChecked([...childItems.map((item) => item[uniqueIdentifier])])
    } else {
      setChildChecked([])
    }
  }

  const handleChildCheckboxChange = (isChecked, value) => {
    const newChildChecked = [...childChecked]
    const currentIndex = newChildChecked.indexOf(value)

    if (isChecked) {
      newChildChecked.push(value)
    } else {
      newChildChecked.splice(currentIndex, 1)
    }
    setChildChecked(newChildChecked)
  }

  const renderchildItems = () => {
    if (!childItems.length) {
      return null
    }
    return childItems.map((checkboxItem, index) => {
      const checkboxValue = checkboxItem[uniqueIdentifier] || ''
      return (
        <CheckboxItem
          data-cy={`checkboxItem_${checkboxValue}`}
          key={index}
          checkboxLabel={checkboxItem.value}
          checkboxValue={checkboxValue}
          checked={childChecked.indexOf(checkboxValue) !== -1}
          checkboxChangeCallback={(checkStatus) =>
            handleChildCheckboxChange(checkStatus, checkboxValue)
          }
        />
      )
    })
  }

  return (
    <div>
      <FormGroup>
        <div className={classes.checkboxGroup}>
          <CheckboxItem
            checkboxLabel={parentCheckboxLabel}
            checkboxValue={parentCheckboxValue}
            checked={parentChecked}
            checkboxChangeCallback={(checkStatus) =>
              handleParentCheckboxChange(checkStatus)
            }
          />
        </div>
        <div className={classes.checkboxChild}>{renderchildItems()}</div>
      </FormGroup>
    </div>
  )
}

export default CheckboxGroup
