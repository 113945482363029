import * as React from 'react'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import ClassificationTypeSelector from './ClassificationTypeSelector'
import ClassificationImageView from './ClassificationImageView'
import ClassificationUploadManager from './ClassificationUploadManager'
import { getAssetClassificationHierarchy } from '../../store/classification/actionCreator'
import { isMemberBelongToRoundel } from '../../helpers/UtilityHelper'
import { AD_ROUNDEL_PARTNER } from '../../constants/adGroups'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles({
  uploadGridContainer: {
    flexDirection: 'column',
  },
  classificationGridContainer: {
    flexDirection: 'row',
    height: '90vh',
  },
  selectorContainer: {
    borderRight: '1px solid #0000001f',
  },
  uploadSection: {
    minHeight: '50vh',
  },
  uploadButton: {
    color: 'white',
    maxHeight: '40px',
    backgroundColor: '#188295',
    '&:hover': {
      backgroundColor: '#115E6C',
    },
  },
})

export default function ClassificationUploadDialog({
  projectData = {},
  acceptedFiles = [],
  memberOf = [],
  onDropFiles = () => {},
}) {
  const { project_name = '' } = projectData
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const isRoundelUser = isMemberBelongToRoundel(memberOf)

  const handleClickOpen = () => {
    setOpen(true)
    const fetchType = isRoundelUser ? AD_ROUNDEL_PARTNER : ''
    dispatch(getAssetClassificationHierarchy(fetchType))
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        className={classes.uploadButton}
        data-cy='uploadAssetsButton'
      >
        Upload Assets
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Upload Assets: {project_name}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.classificationGridContainer}>
          <Grid item xs={3} className={classes.selectorContainer}>
            <ClassificationTypeSelector isRoundelUser={isRoundelUser}/>
          </Grid>
          <Grid item xs={9}>
            <Grid container className={classes.uploadGridContainer}>
              <Grid item className={classes.uploadSection}>
                <ClassificationUploadManager
                  projectName={project_name}
                  acceptedFiles={acceptedFiles}
                  handleClose={handleClose}
                  onDropFiles={onDropFiles}
                />
              </Grid>
              <Grid item>
                <ClassificationImageView />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  )
}
