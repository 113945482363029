import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

const styles = makeStyles((theme) => ({
  root: {
    alignItems: 'baseline !important',
  },
  selectFilter: {
    height: '2.5rem',
    maxWidth: 'fit-content',
    color: 'black',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(136, 136, 136)',
    },
    '& .MuiSvgIcon-root': {
      color: 'rgb(136, 136, 136)',
    },
    fontSize: '0.875rem',
  },
}))
const CustomDropdown = ({
  dropDownName = '',
  dropDownLabel = 'custom',
  dropDownValue = '',
  dropDownOptions = {},
  onDropdownChange = () => {},
}) => {
  const classes = styles()
  const [selectValue, setSelectValue] = useState(dropDownValue)

  const createSortByMenuItems = (sortByOptions = {}) =>
    Object.keys(sortByOptions).map((key) => (
      <MenuItem data-cy={key} key={key} value={key}>
        {sortByOptions[key]}
      </MenuItem>
    ))

  React.useEffect(() => {
    setSelectValue(dropDownValue)
  }, [dropDownValue])

  const onSelectChange = (event) => {
    const sortByOption = typeof event === 'object' ? event.target.value : event
    setSelectValue(sortByOption)
    onDropdownChange(sortByOption)
  }

  return (
    <FormControl variant="outlined" sx={{ m: 1, minWidth: 'fit-content' }}>
      <InputLabel id={`${dropDownLabel + '_sortbylabel'}`}>
        {/* {dropDownName} */}
      </InputLabel>
      <Select
        variant="outlined"
        labelId={`${dropDownLabel + '_sortbylabel'}`}
        id={`${dropDownLabel + '_sortbyid'}`}
        value={selectValue}
        onChange={onSelectChange}
        className={classes.selectFilter}
        data-cy={'sortByDropDown'}
      >
        {dropDownOptions && createSortByMenuItems(dropDownOptions)}
      </Select>
    </FormControl>
  )
}

export default CustomDropdown
